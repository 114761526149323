import React, { useState, useEffect, useCallback } from 'react'
import './offerView.css'
import SideBar from '../NavBar/SideBar'
import axios from 'axios'

const Offer = () => {
  const [formData, setFormData] = useState({
    id: null,
    Image: null,
    Discount: '',
    Hotel: '',
    Location: '',
    RoomType: '',
    TrendingOffers: '',
    Price: '',
    title: '',
    description: '',
    features: [],
    gallery: []
  })

  const [offers, setOffers] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  useEffect(() => {
    fetchOffers()
  }, [])

  const fetchOffers = async () => {
    try {
      const response = await axios.get('https://hospitality-backend.onrender.com/api/offer')
      console.log('Fetched data:', response.data)
      setOffers(response.data.data)
    } catch (error) {
      console.error('Error fetching offers:', error)
      setError('Failed to fetch offers. Please try again later.')
    }
  }

  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }, [])

  const handleImageUpload = (e) => {
    const { name, files } = e.target
    if (name === 'Image') {
      setFormData(prevState => ({
        ...prevState,
        Image: files[0]
      }))
    } else if (name === 'gallery') {
      setFormData(prevState => ({
        ...prevState,
        gallery: Array.from(files)
      }))
    }
  }

  const handleFeaturesChange = (e) => {
    const features = e.target.value.split(',').map(feature => feature.trim())
    setFormData(prev => ({ ...prev, features }))
  }

  const validateForm = () => {
    if (parseFloat(formData.Price) <= 0) {
      setError('Price must be greater than zero')
      return false
    }

    if (parseFloat(formData.Discount) < 0 || parseFloat(formData.Discount) > 100) {
      setError('Discount must be between 0 and 100')
      return false
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validateForm()) return

    setIsLoading(true)
    setError(null)

    const data = new FormData()

    // Append all form fields
    Object.keys(formData).forEach(key => {
      if (key === 'Image' && formData[key] instanceof File) {
        data.append('Image', formData[key])
      } else if (key === 'gallery') {
        formData[key].forEach(file => data.append('gallary', file))
      } else if (key === 'features') {
        data.append('features', JSON.stringify(formData[key]))
      } else if (key !== 'Image' && key !== 'gallery' && key !== 'id') {
        data.append(key, formData[key])
      }
    })

    try {
      let response
      if (isEditing && formData.id) {
        // Update existing offer
        response = await axios.put(`https://hospitality-backend.onrender.com/api/updateOffers/${formData.id}`, data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } else {
        // Create new offer
        response = await axios.post('https://hospitality-backend.onrender.com/api/offers', data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      }

      if (response.status === 200 || response.status === 201) {
        alert(response.data.message || (isEditing ? 'Offer updated successfully!' : 'Offer added successfully!'))
        setFormData({
          id: null,
          Image: null,
          Discount: '',
          Hotel: '',
          Location: '',
          RoomType: '',
          TrendingOffers: '',
          Price: '',
          title: '',
          description: '',
          features: [],
          gallery: []
        })
        setIsEditing(false)
        setIsPopupOpen(false)
        fetchOffers()
      } else {
        throw new Error('Unexpected server response')
      }
    } catch (error) {
      console.error('Submission error:', error.response ? error.response.data : error.message)
      setError(error.response?.data?.message || 'Failed to submit offer')
    } finally {
      setIsLoading(false)
    }
  }

  const handleEdit = (offer) => {
    setFormData({
      ...offer,
      id: offer._id, // Ensure we're setting the correct ID
      Image: null, // Reset image to force re-upload
      gallery: [] // Reset gallery to force re-upload
    })
    setIsEditing(true)
    setIsPopupOpen(true)
  }

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this offer?')) {
      try {
        await axios.delete(`https://hospitality-backend.onrender.com/api/deleteOffers/${id}`)
        await fetchOffers()
        alert("Offer deleted successfully")
      } catch (error) {
        console.error('Error deleting offer:', error)
        setError('Failed to delete offer. Please try again.')
      }
    }
  }

  const handleCancelEdit = () => {
    setIsEditing(false)
    setIsPopupOpen(false)
    setFormData({
      id: null,
      Image: null,
      Discount: '',
      Hotel: '',
      Location: '',
      RoomType: '',
      TrendingOffers: '',
      Price: '',
      title: '',
      description: '',
      features: [],
      gallery: []
    })
  }

  return (
    <div className='sidebar'>
      <SideBar />
      <div className="offer-container">
        <h1 className="offer-title">Offer Management</h1>

        {error && <div className="error-message">{error}</div>}

        <button onClick={() => setIsPopupOpen(true)} className="add-offer-button">
          Add New Offer
        </button>

        {isPopupOpen && (
          <div className="popup-overlay">
            <div className="popup-content">
              <h2 className="offer-title">{isEditing ? 'Edit Offer' : 'Add New Offer'}</h2>
              <form onSubmit={handleSubmit} className="offer-form">
                <div className="form-group">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    placeholder="Enter offer title"
                    className="form-input"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Hotel</label>
                  <input
                    type="text"
                    name="Hotel"
                    value={formData.Hotel}
                    onChange={handleChange}
                    placeholder="Enter hotel name"
                    className="form-input"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Location</label>
                  <input
                    type="text"
                    name="Location"
                    value={formData.Location}
                    onChange={handleChange}
                    placeholder="Enter location"
                    className="form-input"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Room Type</label>
                  <input
                    type="text"
                    name="RoomType"
                    value={formData.RoomType}
                    onChange={handleChange}
                    placeholder="Enter room type"
                    className="form-input"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Discount (%)</label>
                  <input
                    type="number"
                    name="Discount"
                    value={formData.Discount}
                    onChange={handleChange}
                    placeholder="Enter discount percentage"
                    className="form-input"
                    min="0"
                    max="100"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Price</label>
                  <input
                    type="number"
                    name="Price"
                    value={formData.Price}
                    onChange={handleChange}
                    placeholder="Enter price"
                    className="form-input"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Trending Offers</label>
                  <input
                    type="text"
                    name="TrendingOffers"
                    value={formData.TrendingOffers}
                    onChange={handleChange}
                    placeholder="Enter trending offers"
                    className="form-input"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Enter offer description"
                    className="form-input"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Features (comma-separated)</label>
                  <input
                    type="text"
                    name="features"
                    value={formData.features.join(', ')}
                    onChange={handleFeaturesChange}
                    placeholder="Enter features"
                    className="form-input"
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Upload Primary Image</label>
                  <input
                    type="file"
                    name="Image"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="form-input-file"
                  />
                  {formData.Image instanceof File && (
                    <img
                      src={URL.createObjectURL(formData.Image)}
                      alt="Uploaded"
                      className="uploaded-image"
                    />
                  )}
                </div>

                <div className="form-group">
                  <label className="form-label">Upload Gallery Images</label>
                  <input
                    type="file"
                    name="gallery"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="form-input-file"
                    multiple
                  />
                  {formData.gallery.length > 0 && (
                    <div className="gallery-preview">
                      {formData.gallery.map((file, index) => (
                        <img
                          key={index}
                          src={URL.createObjectURL(file)}
                          alt={`Gallery ${index + 1}`}
                          className="uploaded-image"
                        />
                      ))}
                    </div>
                  )}
                </div>

                <div className="form-actions">
                  <button
                    type="button"
                    className="cancel-button"
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="submit-button"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Submitting...' : (isEditing ? 'Update Offer' : 'Add Offer')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {offers.length > 0 && (
          <div className="offer-table-wrapper">
            <h2 className="offer-table-title">Added Offers</h2>
            <table className="offer-table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Hotel</th>
                  <th>Location</th>
                  <th>Discount</th>
                  <th>Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {offers.map((offer, index) => (
                  <tr key={offer._id || index}>
                    <td>
                      {offer.Image && (
                        <img
                          src={offer.Image}
                          alt={`${offer.title} offer`}
                          className="offer-table-image"
                        />
                      )}
                    </td>
                    <td>{offer.title}</td>
                    <td>{offer.Hotel}</td>
                    <td>{offer.Location}</td>
                    <td>{offer.Discount}%</td>
                    <td>${offer.Price}</td>
                    <td className='actions-btn-offer'>
                      <button onClick={() => handleEdit(offer)} className="action-button edit">Edit</button>
                      <button onClick={() => handleDelete(offer._id)} className="action-button delete">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default Offer

