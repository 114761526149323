import React, { useEffect, useState } from "react";
import axios from "axios";
import "./offers.css";
import { IoIosBed } from "react-icons/io";
import { MdBathtub } from "react-icons/md";
import { FaWifi } from "react-icons/fa";
import { MdAirportShuttle } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
const Offers = () => {
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Initialize AOS
    Aos.init({ duration: 2000 });

    // Fetch offers from the API
    const fetchOffers = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('https://hospitality-backend.onrender.com/api/offer');
        console.log("offer",response.data)
        // Assuming the API returns the offers directly
        setOffers(response.data.data);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching offers:", err);
        setError("Failed to load offers. Please try again later.");
        setIsLoading(false);
      }
    };

    fetchOffers();
  }, []);

  // Render loading state
  if (isLoading) {
    return (
      <section className="section offer container">
        <div className="loading">Loading offers...</div>
      </section>
    );
  }

  // Render error state
  if (error) {
    return (
      <section className="section offer container">
        <div className="error">{error}</div>
      </section>
    );
  }

  return (
    <section className="section offer container">
      <div className="secContainer">
        <div
          data-aos="fade-right"
          data-aos-duration="2500"
          className="secIntro"
        >
          <h2 className="secTitle">Special Offers</h2>
          <p>
            From historical cities to natural spectaculars, come see the best of
            the world!
          </p>
        </div>

        <div className="mainContent grid">
          {offers.length > 0 ? (
            offers.map((offer) => (
              <div key={offer._id} data-aos="fade-up" className="singleOffer">
                <div className="destImage">
                  <img 
                    src={offer.Image || 'https://via.placeholder.com/300x200'} 
                    alt={offer.RoomType} 
                  />
                  <span className="discount">{offer.Discount}% off</span>
                </div>

                <div className="offerBody">
                  <div className="price flex">
                    <h4>${offer.Price}</h4>
                    <span className="status">{offer.Hotel}</span>
                  </div>

                  <div className="amenities flex">
                    <div className="singleAmenitiy flex">
                      <IoIosBed className="icon" />
                      <small>2 Beds</small>
                    </div>
                    <div className="singleAmenitiy flex">
                      <MdBathtub className="icon" />
                      <small>1 Bathroom</small>
                    </div>
                    <div className="singleAmenitiy flex">
                      <FaWifi className="icon" />
                      <small>Free Wifi</small>
                    </div>
                    <div className="singleAmenitiy flex">
                      <MdAirportShuttle className="icon" />
                      <small>Shuttle</small>
                    </div>
                  </div>

                  <div className="location flex">
                    <MdLocationPin className="icon" />
                    <small>{offer.Location}</small>
                  </div>

                  <Link to={`/OfferDetails/${offer._id}`}> 
                  {/* <Link to="/OfferDetails"> */}
                    <button className="btn flex">
                      View Details
                      <BsArrowRightShort className="icon" />
                    </button>
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <p>No offers available at the moment.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Offers;