import React, { useState, useEffect } from "react";
import "./blog.css";
import SideBar from "../NavBar/SideBar";
import axios from "axios";

const AddBlog = () => {
  const [formData, setFormData] = useState({
    id: null,
    title: "",
    content: "",
    link: "",
    subtitle: "",
    subsubtitle: "",
    description: "",
    highlights: [],
    image: null,
    gallery: []
  });

  const [blogPosts, setBlogPosts] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("https://hospitality-backend.onrender.com/api/Blog");
      setBlogPosts(response.data.data);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
      alert("Failed to fetch blog posts");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleHighlightsChange = (e) => {
    const highlights = e.target.value.split(',').map(item => item.trim());
    setFormData(prevState => ({
      ...prevState,
      highlights
    }));
  };

  const handleImageUpload = (e) => {
    const { name, files } = e.target;
    if (name === "image") {
      setFormData((prevState) => ({
        ...prevState,
        image: files[0],
      }));
    } else if (name === "gallery") {
      setFormData((prevState) => ({
        ...prevState,
        gallery: Array.from(files),
      }));
    }
  };

  const validateForm = () => {
    if (!formData.title.trim()) {
      alert("Title is required");
      return false;
    }
    if (!formData.content.trim()) {
      alert("Content is required");
      return false;
    }
    if (!formData.description.trim()) {
      alert("Description is required");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (isEditing && !window.confirm("Are you sure you want to update this blog post?")) {
      return;
    }

    try {
      setIsLoading(true);
      const data = new FormData();

      // Append all form data
      data.append("Title", formData.title.trim());
      data.append("Content", formData.content.trim());
      data.append("Link", formData.link.trim());
      data.append("subtitle", formData.subtitle.trim());
      data.append("subsubtitle", formData.subsubtitle.trim());
      data.append("description", formData.description.trim());

      // Append highlights as JSON string
      data.append("highlights", JSON.stringify(formData.highlights));

      // Append images
      if (formData.image) {
        data.append("Image", formData.image);
      }
      formData.gallery.forEach((file) => {
        data.append("gallary", file);
      });

      let response;

      if (isEditing) {
        // Update existing blog post
        response = await axios.put(
          `https://hospitality-backend.onrender.com/api/Blog/${formData.id}`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        // Create new blog post
        response = await axios.post(
          "https://hospitality-backend.onrender.com/api/Blog",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      const savedBlogPost = response.data.data;

      // Update state based on operation type
      if (isEditing) {
        setBlogPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.id === formData.id || post._id === formData.id
              ? savedBlogPost
              : post
          )
        );
        setIsEditing(false);
      } else {
        setBlogPosts((prevPosts) => [...prevPosts, savedBlogPost]);
      }

      // Reset form
      resetForm();
      setIsPopupOpen(false);
      alert(response.data.message);

    } catch (error) {
      console.error(isEditing ? "Error updating blog post:" : "Error saving blog post:", error);
      alert(
        `Failed to ${isEditing ? 'update' : 'save'} blog post: ${error.response?.data?.message || error.message
        }`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (post) => {
    setFormData({
      id: post.id || post._id,
      title: post.Title || "",
      content: post.Content || "",
      link: post.Link || "",
      subtitle: post.subtitle || "",
      subsubtitle: post.subsubtitle || "",
      description: post.description || "",
      highlights: Array.isArray(post.highlights) ? post.highlights : [],
      image: null,
      gallery: []
    });
    setIsEditing(true);
    setIsPopupOpen(true);
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this blog post?")) {
      return;
    }

    try {
      setIsLoading(true);
      await axios.delete(`https://hospitality-backend.onrender.com/api/Blog/${id}`);
      setBlogPosts((prevPosts) => prevPosts.filter((post) =>
        post.id !== id && post._id !== id
      ));
      alert("Blog post deleted successfully!");
    } catch (error) {
      console.error("Error deleting blog post:", error);
      alert(`Failed to delete blog post: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      id: null,
      title: "",
      content: "",
      link: "",
      subtitle: "",
      subsubtitle: "",
      description: "",
      highlights: [],
      image: null,
      gallery: []
    });
    setIsEditing(false);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    document.body.classList.add("no-scroll");
  };

  const handleClosePopup = () => {
    if (isEditing || formData.title || formData.content) {
      if (window.confirm("Are you sure you want to close? Any unsaved changes will be lost.")) {
        resetForm();
        setIsPopupOpen(false);
        document.body.classList.remove("no-scroll");
      }
    } else {
      setIsPopupOpen(false);
      document.body.classList.remove("no-scroll");
    }
  };

  return (
    <div className="sidebar">
      <SideBar />
      <div className="blog-container">
        <h1 className="blog-title">Blog Management</h1>
        <button onClick={handleOpenPopup} className="add-blog-button">
          Add Blog
        </button>

        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner">Loading...</div>
          </div>
        )}

        {isPopupOpen && (
          <div className="popup-overlay">
            <div className="popup-content">
              <h2 className="popup-title">
                {isEditing ? "Edit Blog Post" : "Add New Blog Post"}
              </h2>
              <form onSubmit={handleSubmit} className="blog-form">
                <div className="form-group">
                  <label htmlFor="title">Title *</label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="subtitle">Subtitle</label>
                  <input
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    value={formData.subtitle}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="subsubtitle">Sub-subtitle</label>
                  <input
                    type="text"
                    id="subsubtitle"
                    name="subsubtitle"
                    value={formData.subsubtitle}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="content">Content *</label>
                  <textarea
                    id="content"
                    name="content"
                    value={formData.content}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description">Description *</label>
                  <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="link">Link</label>
                  <input
                    type="text"
                    id="link"
                    name="link"
                    value={formData.link}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="highlights">Highlights (comma-separated)</label>
                  <input
                    type="text"
                    id="highlights"
                    name="highlights"
                    value={formData.highlights.join(', ')}
                    onChange={handleHighlightsChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="image">Primary Image {isEditing && '(Leave empty to keep existing)'}</label>
                  <input
                    type="file"
                    id="image"
                    name="image"
                    onChange={handleImageUpload}
                    accept="image/*"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="gallery">Gallery Images {isEditing && '(Leave empty to keep existing)'}</label>
                  <input
                    type="file"
                    id="gallery"
                    name="gallery"
                    onChange={handleImageUpload}
                    accept="image/*"
                    multiple
                  />
                </div>

                {formData.image && (
                  <div className="image-preview">
                    <h4>Primary Image Preview:</h4>
                    <img
                      src={URL.createObjectURL(formData.image)}
                      alt="Primary"
                      className="uploaded-image"
                    />
                  </div>
                )}

                {formData.gallery.length > 0 && (
                  <div className="image-preview">
                    <h4>Gallery Preview:</h4>
                    <div className="gallery-grid">
                      {formData.gallery.map((image, index) => (
                        <img
                          key={index}
                          src={URL.createObjectURL(image)}
                          alt={`Gallery ${index + 1}`}
                          className="uploaded-image"
                        />
                      ))}
                    </div>
                  </div>
                )}

                <div className="form-actions">
                  <button type="button" onClick={handleClosePopup}>
                    Cancel
                  </button>
                  <button type="submit" disabled={isLoading}>
                    {isEditing ? "Update" : "Add"} {isLoading && "..."}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <div className="blog-table-wrapper">
          <h2 className="blog-table-title">Added Blog Posts</h2>
          <table className="blog-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Content</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {blogPosts.map((post, index) => (
                <tr key={post.id || post._id || index}>
                  <td>
                    {post.Image && (
                      <img
                        src={post.Image}
                        alt={post.Title}
                        className="blog-table-image"
                      />
                    )}
                  </td>
                  <td>{post.Title}</td>
                  <td>{post.Content.slice(0, 80)}...</td>
                  <td className="actions-btn">
                    <button
                      onClick={() => handleEdit(post)}
                      className="action-button edit"
                      disabled={isLoading}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(post.id || post._id)}
                      className="action-button delete"
                      disabled={isLoading}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;

