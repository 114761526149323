import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Calendar, Clock, DollarSign } from 'lucide-react';
import "./TourPage.css";
import Navbar from "../NavBar/Navbar";
import Footer from "../Footer/Footer";
import axios from 'axios';

const DEFAULT_IMAGE = "https://via.placeholder.com/300x200?text=No+Image+Available";

const TourismBooking = () => {
  const { id } = useParams();

  const [destinationData, setDestinationData] = useState({
    title: "",
    description: "",
    CostPerPerson: 0,
    gallery: []
  });
  const [formData, setFormData] = useState({
    date: "",
    time: "",
    tickets: "1",
    extraOffers: false,
    cost: "0",
    name: "",
    email: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDestinationData();
  }, [id]);

  useEffect(() => {
    const basePrice = destinationData.CostPerPerson || 0;
    const extraOffersPrice = formData.extraOffers ? 50 : 0;
    const totalCost = (basePrice * parseInt(formData.tickets) + extraOffersPrice).toFixed(2);
    setFormData(prev => ({ ...prev, cost: totalCost }));
  }, [formData.tickets, formData.extraOffers, destinationData.CostPerPerson]);

  const fetchDestinationData = async () => {
    try {
      const response = await axios.get(`https://hospitality-backend.onrender.com/api/destination/${id}`);

      const destination = response.data;
      if (destination) {
        setDestinationData({
          title: destination.title || "Unnamed Destination",
          description: destination.description || "No description available",
          CostPerPerson: destination.CostPerPerson || 0,
          gallery: destination.gallary && destination.gallary.length > 0
            ? destination.gallary
            : [DEFAULT_IMAGE],
        });
      } else {
        throw new Error('No destination data found');
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching destination data:', error);
      setError('Failed to load destination data. Please try again later.');
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : type === "number" ? value.toString() : value,
    }));
  };

  const validateForm = () => {
    if (!formData.date || !formData.time || !formData.tickets || !formData.name || !formData.email) {
      setError('Please fill in all required fields');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://hospitality-backend.onrender.com/api/booking', {
        destinationId: id,
        date: formData.date,
        time: formData.time,
        tickets: formData.tickets,
        cost: formData.cost,
        name: formData.name,
        email: formData.email,
      });

      if (response.status === 201) {
        alert('Booking submitted successfully!');
        setFormData({
          date: "",
          time: "",
          tickets: "1",
          extraOffers: false,
          name: "",
          email: "",
          cost: (destinationData.CostPerPerson || 0).toString()
        });
      } else {
        throw new Error('Unexpected server response');
      }
    } catch (error) {
      console.error('Submission error:', error.response ? error.response.data : error.message);
      setError(error.response?.data?.message || 'Failed to submit booking');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="tour-page">
      <Navbar />
      <div className="tourism-booking">
        <div className="main-content">
          <div className="left-content">
            <h1>{destinationData.title}</h1>
            <p>{destinationData.description}</p>

            <div className="tour-info">
              <div className="info-item">
                <DollarSign size={24} />
                <div>
                  <strong>Per Person</strong>
                  <span>${(destinationData.CostPerPerson || 0).toFixed(2)}</span>
                </div>
              </div>
              <div className="info-item">
                <Clock size={24} />
                <div>
                  <strong>Duration</strong>
                  <span>1 day</span>
                </div>
              </div>
              <div className="info-item">
                <Calendar size={24} />
                <div>
                  <strong>Tour Type</strong>
                  <span>Budget</span>
                </div>
              </div>
            </div>

            <div className="image-gallery">
              {destinationData.gallery.map((img, index) => (
                <div key={index} className="gallery-image">
                  <img src={img} alt={`Destination ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>

          <div className="booking-section">
            <form className="booking-form" onSubmit={handleSubmit}>
              <h2>Book Your Tour</h2>
              {error && <div className="error-message">{error}</div>}
              <div className="form-group">
                <label htmlFor="name">Full Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="date">Date:</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="time">Time:</label>
                <input
                  type="time"
                  id="time"
                  name="time"
                  value={formData.time}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="tickets">Number of Tickets:</label>
                <input
                  type="number"
                  id="tickets"
                  name="tickets"
                  min="1"
                  value={formData.tickets}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="extraOffers">
                  <input
                    type="checkbox"
                    id="extraOffers"
                    name="extraOffers"
                    checked={formData.extraOffers}
                    onChange={handleInputChange}
                  />
                  Include Extra Offers (+$50)
                </label>
              </div>
              <div className="total">
                <strong>Total Cost: ${formData.cost}</strong>
              </div>
              <button type="submit" className="book-now" disabled={isLoading}>
                {isLoading ? 'Booking...' : 'Book Now'}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TourismBooking;

