import React, { useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';
import { useNavigate, useLocation } from 'react-router-dom';
import "./loginSignUp.css";

const LoginSignUp = ({ onSubmit }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Static credentials
    const staticEmail = "admin@uhdss.com";
    const staticPassword = "Admin@2024";

    if (email && password) {
      if (email === staticEmail && password === staticPassword) {
        // Simulate user data
        const user = {
          id: 1,
          email: staticEmail,
          role: 'admin',
          name: 'Admin User',
        };

        // Generate a simple token (for demo purposes)
        const token = btoa(user.email + ':' + new Date().getTime());

        // Store auth data in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));

        // Redirect to admin page
        navigate('/admin');
      } else {
        setMessage('Invalid email or password');
      }
    } else {
      setMessage('Please fill in all fields');
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    // Add your forgot password logic here
    navigate('/forgot-password');
  };

  return (
    <div className="containerLogin">
      <div className="header">
        <div className="text">Login</div>
        <div className="underline"></div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="inputs">
          <div className="input">
            <MdEmail className="icon" />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="input">
            <RiLockPasswordFill className="icon" />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="forget-password">
          Forgot password?
          <span onClick={handleForgotPassword}>Click here</span>
        </div>

        <div className="submit-container">
          <button type="submit" className="submit gray">
            Login
          </button>
        </div>

        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default LoginSignUp;
