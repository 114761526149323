import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './OfferDetails.css';
import Navbar from "./../../NavBar/Navbar";
import Footer from "../../Footer/Footer";
import axios from 'axios';

const OfferDetails = () => {
  const { id } = useParams();
  const [offerData, setOfferData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    checkIn: '',
    checkOut: '',
    guests: 1
  });

  useEffect(() => {
    const fetchOfferData = async () => {
      try {
        const response = await axios.get(`https://hospitality-backend.onrender.com/api/offer/${id}`);
        setOfferData(response.data.data);
        console.log("fetched data",response.data.data)
        setLoading(false);
      } catch (err) {
        console.error('Error fetching offer data:', err);
        setError('Failed to load offer data. Please try again later.');
        setLoading(false);
      }
    };

    fetchOfferData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Booking submitted:', formData);
    
    try {
      const response = await axios.post('https://hospitality-backend.onrender.com/api/bookingapartment', formData);
      console.log('Booking response:', response.data);
      alert('Booking submitted successfully!');
    } catch (error) {
      console.error('Error submitting booking:', error);
      alert('Failed to submit booking. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!offerData) return <div>No offer data found.</div>;

  return (
    <>
      <Navbar />
      
      <div className="offer-details">
        <div className="offer-images">
          {offerData.gallary && offerData.gallary.map((image, index) => (
            <img key={index} src={image} alt={`Room view ${index + 1}`} className="room-image" />
          ))}
        </div>
        <div className="offer-content">
          <h1>{offerData.title}</h1>
          <p className="offer-description">{offerData.description}</p>
          <div className="price-info">
            <span className="price">${offerData.Price}</span> per night
          </div>
          <div className="amenities">
            <h2>Features</h2>
            <ul>
              {offerData.features && offerData.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className="booking-form">
            <h2>Book Your Stay</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="name">Full Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="checkIn">Check-in:</label>
                  <input
                    type="date"
                    id="checkIn"
                    name="checkIn"
                    value={formData.checkIn}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="checkOut">Check-out:</label>
                  <input
                    type="date"
                    id="checkOut"
                    name="checkOut"
                    value={formData.checkOut}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="guests">Guests:</label>
                  <input
                    type="number"
                    id="guests"
                    name="guests"
                    min="1"
                    max="4"
                    value={formData.guests}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="submit-btn">Book Now</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OfferDetails;

