import React from "react";
import "./App.css";

import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
// Components
import Navbar from "./components/NavBar/Navbar";
import LoginSignUp from "./components/LoginSignUp/LoginSignUp";
import VerifyEmail from "./components/LoginSignUp/VerifyEmail";
import Layout from "./components/Dashboard/components/Layout/Layout";
import Destination from "./components/Destination/single-destionation/Destination";
import ContactUs from "./components/Contact-us/ContactUs";
import DestinationView from "./components/Destination/Destination.view";
import OfferView from "./components/Dashboard/components/Offer/Offer.view";
import AddBlog from "./components/Dashboard/components/Blog/AddBlog";
import TourPage from "./components/Popular/TourPage.js";
import OfferDetails from "./components/Offers/OfferDetails/OfferDetails.js";
import BlogDetails from "./components/Blog/BlogDetails/BlogDetails.js";
import Booking from "./components/Dashboard/components/Bookings/Booking.js";
import WebPages from "./components/webPages";
import BookingApartment from "./components/Dashboard/components/Bookings/BookingApartment.js";
// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<LoginSignUp />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/destination" element={<Destination />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/TourPage/:id" element={<TourPage />} />
        <Route path="/TourPage" element={<TourPage />} />
        <Route path="/OfferDetails/:id" element={<OfferDetails />} />
        <Route path="*" element={<WebPages />} />

        {/* Protected Admin Routes */}
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Admin/destinationView"
          element={
            <ProtectedRoute>
              <DestinationView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Admin/OfferView"
          element={
            <ProtectedRoute>
              <OfferView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Admin/AddBlog"
          element={
            <ProtectedRoute>
              <AddBlog />
            </ProtectedRoute>
          }
        />
        <Route path="/BlogDetails/:id" element={<BlogDetails />} />

        {/* Protected Client Routes */}
        <Route
          path="/client/*"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/Booking"
          element={
            <ProtectedRoute>
              <Booking />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/BookingApartment"
          element={
            <ProtectedRoute>
              <BookingApartment />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
