import React from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../NavBar/Navbar";
import "./Destination.css"; 

export default function Destination() {
  return (
    <div>
      <Navbar />
      <div className="destination">
        {/* <h1 className="destination-title">Golden Gate Bridge</h1> */}
        <div className="destination-container">
     
          <div className="destination-content">
            <h2> The Golden Gate Bridge</h2>
            <p>
              The **Golden Gate Bridge**, an iconic symbol of San Francisco and
              the United States, is a marvel of engineering and beauty. Opened
              in 1937, it connects the city of San Francisco to Marin County
              across the Golden Gate Strait. At the time of its completion, it
              was the longest and tallest suspension bridge in the world, with
              a main span of 4,200 feet and a total height of 746 feet.
            </p>
            <h3>Historical Significance</h3>
            <p>
              Designed by Joseph Strauss, Irving Morrow, and Charles Alton
              Ellis, the Golden Gate Bridge faced challenges during its
              construction, including high winds, strong tides, and the Great
              Depression. Despite these difficulties, it became a symbol of
              hope and progress for the United States.
            </p>
            <h3>Tourist Attractions</h3>
            <p>
              Millions of visitors flock to the bridge each year to walk, bike,
              and enjoy breathtaking views of San Francisco Bay. Popular spots
              include the Golden Gate Park, Fort Point, and the Marin Headlands,
              offering stunning perspectives of this architectural masterpiece.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}